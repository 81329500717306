import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { captureConsoleIntegration } from '@sentry/integrations';
import { UAParser } from 'ua-parser-js';

export default ({ $config }) => {
  const ignoreErrors = [
    // Email link Microsoft Outlook crawler compatibility error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',

    // Все сетевые ошибки, которые можно игнорировать
    'Network request failed',
    'Failed to fetch',
    'NetworkError',
    'Network Error',
    'Request aborted',
    'timeout of 30000ms exceeded',
    'Load failed', //https://sentry.io/answers/load-failed-javascript/
    '[axios:timeout]',

    // Баг во vuetify, который скорее всего уже не исправят. Появляется при определенных условиях в момент
    // скролла во VDialog
    't.hasAttribute is not a function',

    // Баг возникает только на спам ip адресах
    'Unexpected token .',

    // Воспроизводится в iOS Mobile Safari UI/WKWebView (instagram, facebook)
    "Can't find variable: _AutofillCallbackHandler",

    // Воспроизводится в довольно специфичных браузерах: Bytespider (большая часть), Chrome mobile (это исключение), Samsung Internet, Chrome (опять же, хром исключение), Facebook, Chrome Webview, Headless Chrome. Судя по реплаям на работе приложения это не сказывается
    "Non-Error promise rejection captured with value: Cannot set property 'vt' of undefined",
    "Non-Error promise rejection captured with value: Cannot read property 'setPageIdValue' of undefined",

    // Воспроизводится в WebView, на работу не влияет
    "Cannot read properties of undefined (reading 'domInteractive')",

    // Ошибка Sentry. Воспроизводится в webView. На работу не влияет.
    'auryc is not defined',

    // Сторонний скрипт. Судя по реплаям на работе приложения это не сказывается.
    'minit.boot.min.js is already loaded!',

    // Ошибка reCAPTCHA. https://github.com/getsentry/sentry-javascript/issues/2514
    'Non-Error promise rejection captured with value: Timeout (b)',

    // Ошибка reCAPTCHA. https://github.com/getsentry/sentry-javascript/issues/2514
    'Non-Error promise rejection captured with value: Timeout',

    // Ошибка возникает в браузерах от Instagram и Facebook. Связана со сторонним расширением. На работу не влияет.
    "Can't find variable: IFrameMessageHandler",
    // Таймаут рекапчи. Решили добавить в игнор.
    'reCAPTCHA timeout',
    // Ошибка трекера или блокировщика рекламы. На работу не влияет.
    'UET is not defined',
  ];

  if ($config.COUNTRY === 'mx') {
    ignoreErrors.push(
      // Так и не решенная маркетингом проблема с пушем событий в гтм на Мексике
      "undefined is not an object (evaluating 'this.$gtm.push')",
      "Cannot read properties of undefined (reading 'push')",
    );
  }

  Sentry.init({
    Vue,
    enabled: $config.sentryEnable,
    debug: $config.sentryDebug,
    dsn: $config.sentryDSN,
    environment: $config.sentryEnvironment,
    tracesSampleRate: 0.25,
    integrations: [captureConsoleIntegration({ levels: ['error'] })],
    ignoreErrors,
    beforeSend: (event) => {
      if (process.client) {
        const userAgent = event.request.headers['User-Agent'];
        const { browser } = UAParser(userAgent);

        //Если браузер Chrome WebView или Chrome Headless, то не отправляем событие
        if (
          browser.name === 'Chrome WebView' ||
          browser.name === 'Chrome Headless'
        ) {
          return null;
        }

        return event;
      }

      return event;
    },
  });
};
